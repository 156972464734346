export default [
    {
        header: 'Account',
        icon: 'UsersIcon',
        children: [
            {
                title: 'Receipt',
                icon: 'FileTextIcon',
                children: [
                {
                    title: 'List',
                    route: 'accounts-receipt-list',
                    action: 'read',
                    resource: 'Receipt-List',
                },
                {
                    title: 'Preview',
                    route: { name: 'accounts-receipt-preview', params: { id: 0 } },
                    action: 'read',
                    resource: 'Receipt-Preview',
                },
                {
                    title: 'Approvals',
                    route: 'accounts-receipt-approvals',
                    action: 'read',
                    resource: 'Receipt-Approvals',
                },
                ],
            },
            {
                title: 'Payment',
                icon: 'CreditCardIcon',
                children: [
                {
                    title: 'Category',
                    route: 'accounts-payment-category',
                    action: 'read',
                    resource: 'Payment-Category',
                },
                {
                    title: 'List',
                    route: 'accounts-payment-list',
                    action: 'read',
                    resource: 'Payment-List',
                },
                {
                    title: 'Preview',
                    route: { name: 'accounts-payment-preview', params: { id: 0 } },
                    action: 'read',
                    resource: 'Payment-Preview',
                },
                {
                    title: 'Approvals',
                    route: 'accounts-payment-approvals',
                    action: 'read',
                    resource: 'Payment-Approvals',
                }
                ],
            },
            {
                title: 'Statements',
                icon: 'BarChartIcon',
                children: [
                {
                    title: 'Income Statement',
                    route: 'account-income-report',
                    action: 'read',
                    resource: 'Statements-Income-Statement',
                },
                // {
                //     title: 'Cashflow',
                //     route: 'account-cashflow-report',
                //     action: 'read',
                //     resource: 'Statements-Cashflow',
                // },
                ],
            }
        ]
    }
      
]