export default [

    // {
    //   path: '/accounts/receipt/add/',
    //   name: 'accounts-receipt-add',
    //   component: () => import('@/views/accounts/receipt/receipt-add/ReceiptAdd.vue'),
    // },
    {
      path: '/accounts/receipt/list',
      name: 'accounts-receipt-list',
      component: () => import('@/views/accounts/receipt/receipt-list/ReceiptList.vue'),
    },
    {
      path: '/accounts/receipt/preview/:id',
      name: 'accounts-receipt-preview',
      component: () => import('@/views/accounts/receipt/receipt-preview/ReceiptPreview.vue'),
    },
    
    {
      path: '/accounts/receipt/approvals',
      name: 'accounts-receipt-approvals',
      component: () => import('@/views/accounts/receipt/receipt-approval/ReceiptApproval.vue'),
    },

    {
      path: '/accounts/payment/category',
      name: 'accounts-payment-category',
      component: () => import('@/views/accounts/payment/payment-category/PaymentCategory.vue'),
    },
    {
      path: '/accounts/payment/list',
      name: 'accounts-payment-list',
      component: () => import('@/views/accounts/payment/payment-list/PaymentList.vue'),
    },
    {
      path: '/accounts/payment/preview/:id',
      name: 'accounts-payment-preview',
      component: () => import('@/views/accounts/payment/payment-preview/PaymentPreview.vue'),
    },
    {
      path: '/accounts/payment/approvals',
      name: 'accounts-payment-approvals',
      component: () => import('@/views/accounts/payment/payment-approval/PaymentApproval.vue'),
    },
    
    {
      path: '/accounts/income/report',
      name: 'accounts-income-report',
      component: () => import('@/views/accounts/income/income-report/IncomeReport.vue'),
    },
    // {
    //   path: '/accounts/payment/approvals',
    //   name: 'accounts-payment-approvals',
    //   component: () => import('@/views/accounts/payment/payment-approval/PaymentApproval.vue'),
    // },
    
    // membership
    // {
    //   path: '/account/members/list',
    //   name: 'account-members-list',
    //   component: () => import('@/views/account/membership/members-list/MemberList.vue'),
    // },
    // {
    //   path: '/account/members/view/:id',
    //   name: 'account-members-view',
    //   component: () => import('@/views/account/membership/members-view/MemberView.vue'),
    // },
    // {
    //   path: '/account/members/edit/:id',
    //   name: 'account-members-edit',
    //   component: () => import('@/views/account/membership/members-edit/MemberEdit.vue'),
    // },
    // {
    //   path: '/account/members/add',
    //   name: 'account-members-add',
    //   component: () => import('@/views/account/membership/members-add/MemberAdd.vue'),
    // },
  
    
  ]
  