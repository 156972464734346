import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  members: [
    {
      id: 1,
      fullName: 'Galen Slixby',
      memID: 'M/005',
      dayborn: 'wednesday',
      gender: 'male',
      dob: 'Oct 12 1998',
      pob: 'Texas city',
      contact1: {
        countryCode: '(479)',
        tel: '232-9151'
      },
      contact2: {
        countryCode: '(233)',
        tel: '545-6541'
      },
      email: 'gslixby0@abc.net.au',
      currentPlan: 'enterprise',
      status: 'invalid',
      avatar: '',
      languages: ['English', 'Twi', 'Ga'],
      nationality: 'Ghanaian',
      workStatus: 'employed',
      occupation: 'Accountant',
      pow: 'KPMG GHANA',
      addressName: 'Market High Street',
      residence: 'Teshie Nungua',
      address: [5.634049,-0.134197],
      address1: '5.634049, -0.134197',
      hometown: 'La Abomli',
      region: 'Greater Accra',
      district: 'La Dadekotopon',
      nokFname: 'John Doe',
      nokCountry: '+1',
      nokCont: {
        countryCode: '(+1)',
        tel: '703-654-8552'
      },
      nokRel: 'Sibling',
      emgFname: 'John Doe',
      emgCountry: '+1',
      emgCont: {
        countryCode: '(+1)',
        tel: '703-654-8552'
      },
      emgRel: 'Sibling',
      maritalStatus: 'single'
    },
    {
      id: 2,
      fullName: 'Halsey Redmore',
      memID: 'F/100',
      dayborn: 'sunday',
      gender: 'female',
      dob: 'May 05 1980',
      contact1: {
        countryCode: '(472)',
        tel: '607-9137'
      },
      email: 'hredmore1@imgur.com',
      currentPlan: 'team',
      status: 'distant',
    //   avatar: require('@/assets/images/avatars/10.png'),
      avatar: '',
      languages: ['English', 'Ewe'],
      nationality: 'Ghanaian',
      workStatus: 'unemployed',
    },
    {
      id: 3,
      fullName: 'Marjory Sicely',
      memID: 'M/050',
      dayborn: 'monday',
      gender: 'male',
      dob: 'Sep 09 1955',
      contact1: {
        countryCode: '(321)',
        tel: '264-4599'
      },
      email: 'msicely2@who.int',
      currentPlan: 'enterprise',
      status: 'active',
      avatar: require('@/assets/images/avatars/1.png'),
    },
    {
      id: 4,
      fullName: 'Cyrill Risby',
      memID: 'M/004',
      dayborn: 'monday',
      gender: 'female',
      dob: 'Dec 22 1975',
      contact1: {
        countryCode: '(923)',
        tel: '690-6806'
      },
      email: 'crisby3@wordpress.com',
      currentPlan: 'team',
      status: 'invalid',
      avatar: require('@/assets/images/avatars/9.png'),
    },
    // {
    //   id: 5,
    //   name: 'Maggy Hurran',
    //   memID: 'Aimbo PVT LTD',
    //   dayborn: 'subscriber',
    //   gender: 'mhurran4',
    //   dob: 'Pakistan',
    //   contact1: '(669) 914-1078',
    //   email: 'mhurran4@yahoo.co.jp',
    //   currentPlan: 'enterprise',
    //   status: 'pending',
    //   avatar: require('@/assets/images/avatars/10.png'),
    // },
    // {
    //   id: 6,
    //   name: 'Silvain Halstead',
    //   memID: 'Jaxbean PVT LTD',
    //   dayborn: 'author',
    //   gender: 'shalstead5',
    //   dob: 'China',
    //   contact1: '(958) 973-3093',
    //   email: 'shalstead5@shinystat.com',
    //   currentPlan: 'memID',
    //   status: 'active',
    //   avatar: '',
    // },
    // {
    //   id: 7,
    //   name: 'Breena Gallemore',
    //   memID: 'Jazzy PVT LTD',
    //   dayborn: 'subscriber',
    //   gender: 'bgallemore6',
    //   dob: 'Canada',
    //   contact1: '(825) 977-8152',
    //   email: 'bgallemore6@boston.com',
    //   currentPlan: 'memID',
    //   status: 'pending',
    //   avatar: '',
    // },
    // {
    //   id: 8,
    //   name: 'Kathryne Liger',
    //   memID: 'Pixoboo PVT LTD',
    //   dayborn: 'author',
    //   gender: 'kliger7',
    //   dob: 'France',
    //   contact1: '(187) 440-0934',
    //   email: 'kliger7@vinaora.com',
    //   currentPlan: 'enterprise',
    //   status: 'pending',
    //   avatar: require('@/assets/images/avatars/9.png'),
    // },
    // {
    //   id: 9,
    //   name: 'Franz Scotfurth',
    //   memID: 'Tekfly PVT LTD',
    //   dayborn: 'subscriber',
    //   gender: 'fscotfurth8',
    //   dob: 'China',
    //   contact1: '(978) 146-5443',
    //   email: 'fscotfurth8@dailymotion.com',
    //   currentPlan: 'team',
    //   status: 'pending',
    //   avatar: require('@/assets/images/avatars/2.png'),
    // },
    // {
    //   id: 10,
    //   name: 'Jillene Bellany',
    //   memID: 'Gigashots PVT LTD',
    //   dayborn: 'maintainer',
    //   gender: 'jbellany9',
    //   dob: 'Jamaica',
    //   contact1: '(589) 284-6732',
    //   email: 'jbellany9@kickstarter.com',
    //   currentPlan: 'memID',
    //   status: 'inactive',
    //   avatar: require('@/assets/images/avatars/9.png'),
    // },
    // {
    //   id: 11,
    //   name: 'Jonah Wharlton',
    //   memID: 'Eare PVT LTD',
    //   dayborn: 'subscriber',
    //   gender: 'jwharltona',
    //   dob: 'United States',
    //   contact1: '(176) 532-6824',
    //   email: 'jwharltona@oakley.com',
    //   currentPlan: 'team',
    //   status: 'inactive',
    //   avatar: require('@/assets/images/avatars/4.png'),
    // },
    // {
    //   id: 12,
    //   name: 'Seth Hallam',
    //   memID: 'Yakitri PVT LTD',
    //   dayborn: 'subscriber',
    //   gender: 'shallamb',
    //   dob: 'Peru',
    //   contact1: '(234) 464-0600',
    //   email: 'shallamb@hugedomains.com',
    //   currentPlan: 'team',
    //   status: 'pending',
    //   avatar: require('@/assets/images/avatars/5.png'),
    // },
    // {
    //   id: 13,
    //   name: 'Yoko Pottie',
    //   memID: 'Leenti PVT LTD',
    //   dayborn: 'subscriber',
    //   gender: 'ypottiec',
    //   dob: 'Philippines',
    //   contact1: '(907) 284-5083',
    //   email: 'ypottiec@privacy.gov.au',
    //   currentPlan: 'basic',
    //   status: 'inactive',
    //   avatar: require('@/assets/images/avatars/7.png'),
    // },
    // {
    //   id: 14,
    //   name: 'Maximilianus Krause',
    //   memID: 'Digitube PVT LTD',
    //   dayborn: 'author',
    //   gender: 'mkraused',
    //   dob: 'Democratic Republic of the Congo',
    //   contact1: '(167) 135-7392',
    //   email: 'mkraused@stanford.edu',
    //   currentPlan: 'team',
    //   status: 'active',
    //   avatar: require('@/assets/images/avatars/9.png'),
    // },
    // {
    //   id: 15,
    //   name: 'Zsazsa McCleverty',
    //   memID: 'Kaymbo PVT LTD',
    //   dayborn: 'maintainer',
    //   gender: 'zmcclevertye',
    //   dob: 'France',
    //   contact1: '(317) 409-6565',
    //   email: 'zmcclevertye@soundcloud.com',
    //   currentPlan: 'enterprise',
    //   status: 'active',
    //   avatar: require('@/assets/images/avatars/2.png'),
    // },
    // {
    //   id: 16,
    //   name: 'Bentlee Emblin',
    //   memID: 'Yambee PVT LTD',
    //   dayborn: 'author',
    //   gender: 'bemblinf',
    //   dob: 'Spain',
    //   contact1: '(590) 606-1056',
    //   email: 'bemblinf@wired.com',
    //   currentPlan: 'memID',
    //   status: 'active',
    //   avatar: require('@/assets/images/avatars/6.png'),
    // },
    // {
    //   id: 17,
    //   name: 'Brockie Myles',
    //   memID: 'Wikivu PVT LTD',
    //   dayborn: 'maintainer',
    //   gender: 'bmylesg',
    //   dob: 'Poland',
    //   contact1: '(553) 225-9905',
    //   email: 'bmylesg@amazon.com',
    //   currentPlan: 'basic',
    //   status: 'active',
    //   avatar: '',
    // },
    // {
    //   id: 18,
    //   name: 'Bertha Biner',
    //   memID: 'Twinte PVT LTD',
    //   dayborn: 'editor',
    //   gender: 'bbinerh',
    //   dob: 'Yemen',
    //   contact1: '(901) 916-9287',
    //   email: 'bbinerh@mozilla.com',
    //   currentPlan: 'team',
    //   status: 'active',
    //   avatar: require('@/assets/images/avatars/7.png'),
    // },
    // {
    //   id: 19,
    //   name: 'Travus Bruntjen',
    //   memID: 'Cogidoo PVT LTD',
    //   dayborn: 'admin',
    //   gender: 'tbruntjeni',
    //   dob: 'France',
    //   contact1: '(524) 586-6057',
    //   email: 'tbruntjeni@sitemeter.com',
    //   currentPlan: 'enterprise',
    //   status: 'active',
    //   avatar: '',
    // },
    // {
    //   id: 20,
    //   name: 'Wesley Burland',
    //   memID: 'Bubblemix PVT LTD',
    //   dayborn: 'editor',
    //   gender: 'wburlandj',
    //   dob: 'Honduras',
    //   contact1: '(569) 683-1292',
    //   email: 'wburlandj@uiuc.edu',
    //   currentPlan: 'team',
    //   status: 'inactive',
    //   avatar: require('@/assets/images/avatars/6.png'),
    // },
    // {
    //   id: 21,
    //   name: 'Selina Kyle',
    //   memID: 'Wayne Enterprises',
    //   dayborn: 'admin',
    //   gender: 'catwomen1940',
    //   dob: 'USA',
    //   contact1: '(829) 537-0057',
    //   email: 'irena.dubrovna@wayne.com',
    //   currentPlan: 'team',
    //   status: 'active',
    //   avatar: require('@/assets/images/avatars/1.png'),
    // },
    // {
    //   id: 22,
    //   name: 'Jameson Lyster',
    //   memID: 'Quaxo PVT LTD',
    //   dayborn: 'editor',
    //   gender: 'jlysterl',
    //   dob: 'Ukraine',
    //   contact1: '(593) 624-0222',
    //   email: 'jlysterl@guardian.co.uk',
    //   currentPlan: 'memID',
    //   status: 'inactive',
    //   avatar: require('@/assets/images/avatars/8.png'),
    // },
    // {
    //   id: 23,
    //   name: 'Kare Skitterel',
    //   memID: 'Ainyx PVT LTD',
    //   dayborn: 'maintainer',
    //   gender: 'kskitterelm',
    //   dob: 'Poland',
    //   contact1: '(254) 845-4107',
    //   email: 'kskitterelm@washingtonpost.com',
    //   currentPlan: 'basic',
    //   status: 'pending',
    //   avatar: require('@/assets/images/avatars/3.png'),
    // },
    // {
    //   id: 24,
    //   name: 'Cleavland Hatherleigh',
    //   memID: 'Flipopia PVT LTD',
    //   dayborn: 'admin',
    //   gender: 'chatherleighn',
    //   dob: 'Brazil',
    //   contact1: '(700) 783-7498',
    //   email: 'chatherleighn@washington.edu',
    //   currentPlan: 'team',
    //   status: 'pending',
    //   avatar: require('@/assets/images/avatars/2.png'),
    // },
    // {
    //   id: 25,
    //   name: 'Adeline Micco',
    //   memID: 'Topicware PVT LTD',
    //   dayborn: 'admin',
    //   gender: 'amiccoo',
    //   dob: 'France',
    //   contact1: '(227) 598-1841',
    //   email: 'amiccoo@whitehouse.gov',
    //   currentPlan: 'enterprise',
    //   status: 'pending',
    //   avatar: '',
    // },
    // {
    //   id: 26,
    //   name: 'Hugh Hasson',
    //   memID: 'Skinix PVT LTD',
    //   dayborn: 'admin',
    //   gender: 'hhassonp',
    //   dob: 'China',
    //   contact1: '(582) 516-1324',
    //   email: 'hhassonp@bizjournals.com',
    //   currentPlan: 'basic',
    //   status: 'inactive',
    //   avatar: require('@/assets/images/avatars/4.png'),
    // },
    // {
    //   id: 27,
    //   name: 'Germain Jacombs',
    //   memID: 'Youopia PVT LTD',
    //   dayborn: 'editor',
    //   gender: 'gjacombsq',
    //   dob: 'Zambia',
    //   contact1: '(137) 467-5393',
    //   email: 'gjacombsq@jigsy.com',
    //   currentPlan: 'enterprise',
    //   status: 'active',
    //   avatar: require('@/assets/images/avatars/10.png'),
    // },
    // {
    //   id: 28,
    //   name: 'Bree Kilday',
    //   memID: 'Jetpulse PVT LTD',
    //   dayborn: 'maintainer',
    //   gender: 'bkildayr',
    //   dob: 'Portugal',
    //   contact1: '(412) 476-0854',
    //   email: 'bkildayr@mashable.com',
    //   currentPlan: 'team',
    //   status: 'active',
    //   avatar: '',
    // },
    // {
    //   id: 29,
    //   name: 'Candice Pinyon',
    //   memID: 'Kare PVT LTD',
    //   dayborn: 'maintainer',
    //   gender: 'cpinyons',
    //   dob: 'Sweden',
    //   contact1: '(170) 683-1520',
    //   email: 'cpinyons@behance.net',
    //   currentPlan: 'team',
    //   status: 'active',
    //   avatar: require('@/assets/images/avatars/7.png'),
    // },
    // {
    //   id: 30,
    //   name: 'Isabel Mallindine',
    //   memID: 'Voomm PVT LTD',
    //   dayborn: 'subscriber',
    //   gender: 'imallindinet',
    //   dob: 'Slovenia',
    //   contact1: '(332) 803-1983',
    //   email: 'imallindinet@shinystat.com',
    //   currentPlan: 'team',
    //   status: 'pending',
    //   avatar: '',
    // },
    // {
    //   id: 31,
    //   name: 'Gwendolyn Meineken',
    //   memID: 'Oyondu PVT LTD',
    //   dayborn: 'admin',
    //   gender: 'gmeinekenu',
    //   dob: 'Moldova',
    //   contact1: '(551) 379-7460',
    //   email: 'gmeinekenu@hc360.com',
    //   currentPlan: 'basic',
    //   status: 'dead',
    //   avatar: require('@/assets/images/avatars/1.png'),
    // },
    // {
    //   id: 32,
    //   name: 'Rafaellle Snowball',
    //   memID: 'Fivespan PVT LTD',
    //   dayborn: 'editor',
    //   gender: 'rsnowballv',
    //   dob: 'Philippines',
    //   contact1: '(974) 829-0911',
    //   email: 'rsnowballv@indiegogo.com',
    //   currentPlan: 'basic',
    //   status: 'invalid',
    //   avatar: require('@/assets/images/avatars/5.png'),
    // },
    // {
    //   id: 33,
    //   name: 'Rochette Emer',
    //   memID: 'Thoughtworks PVT LTD',
    //   dayborn: 'admin',
    //   gender: 'remerw',
    //   dob: 'North Korea',
    //   contact1: '(841) 889-3339',
    //   email: 'remerw@blogtalkradio.com',
    //   currentPlan: 'basic',
    //   status: 'active',
    //   avatar: require('@/assets/images/avatars/8.png'),
    // },
    // {
    //   id: 34,
    //   name: 'Ophelie Fibbens',
    //   memID: 'Jaxbean PVT LTD',
    //   dayborn: 'subscriber',
    //   gender: 'ofibbensx',
    //   dob: 'Indonesia',
    //   contact1: '(764) 885-7351',
    //   email: 'ofibbensx@booking.com',
    //   currentPlan: 'memID',
    //   status: 'active',
    //   avatar: require('@/assets/images/avatars/4.png'),
    // },
    // {
    //   id: 35,
    //   name: 'Stephen MacGilfoyle',
    //   memID: 'Browseblab PVT LTD',
    //   dayborn: 'maintainer',
    //   gender: 'smacgilfoyley',
    //   dob: 'Japan',
    //   contact1: '(350) 589-8520',
    //   email: 'smacgilfoyley@bigcartel.com',
    //   currentPlan: 'memID',
    //   status: 'pending',
    //   avatar: '',
    // },
    // {
    //   id: 36,
    //   name: 'Bradan Rosebotham',
    //   memID: 'Agivu PVT LTD',
    //   dayborn: 'subscriber',
    //   gender: 'brosebothamz',
    //   dob: 'Belarus',
    //   contact1: '(882) 933-2180',
    //   email: 'brosebothamz@tripadvisor.com',
    //   currentPlan: 'team',
    //   status: 'inactive',
    //   avatar: '',
    // },
    // {
    //   id: 37,
    //   name: 'Skip Hebblethwaite',
    //   memID: 'Katz PVT LTD',
    //   dayborn: 'admin',
    //   gender: 'shebblethwaite10',
    //   dob: 'Canada',
    //   contact1: '(610) 343-1024',
    //   email: 'shebblethwaite10@arizona.edu',
    //   currentPlan: 'memID',
    //   status: 'inactive',
    //   avatar: require('@/assets/images/avatars/9.png'),
    // },
    // {
    //   id: 38,
    //   name: 'Moritz Piccard',
    //   memID: 'Twitternation PVT LTD',
    //   dayborn: 'maintainer',
    //   gender: 'mpiccard11',
    //   dob: 'Croatia',
    //   contact1: '(365) 277-2986',
    //   email: 'mpiccard11@vimeo.com',
    //   currentPlan: 'enterprise',
    //   status: 'inactive',
    //   avatar: require('@/assets/images/avatars/1.png'),
    // },
    // {
    //   id: 39,
    //   name: 'Tyne Widmore',
    //   memID: 'Yombu PVT LTD',
    //   dayborn: 'subscriber',
    //   gender: 'twidmore12',
    //   dob: 'Finland',
    //   contact1: '(531) 731-0928',
    //   email: 'twidmore12@bravesites.com',
    //   currentPlan: 'team',
    //   status: 'pending',
    //   avatar: '',
    // },
    // {
    //   id: 40,
    //   name: 'Florenza Desporte',
    //   memID: 'Kamba PVT LTD',
    //   dayborn: 'author',
    //   gender: 'fdesporte13',
    //   dob: 'Ukraine',
    //   contact1: '(312) 104-2638',
    //   email: 'fdesporte13@omniture.com',
    //   currentPlan: 'memID',
    //   status: 'active',
    //   avatar: require('@/assets/images/avatars/6.png'),
    // },
    // {
    //   id: 41,
    //   name: 'Edwina Baldetti',
    //   memID: 'Dazzlesphere PVT LTD',
    //   dayborn: 'maintainer',
    //   gender: 'ebaldetti14',
    //   dob: 'Haiti',
    //   contact1: '(315) 329-3578',
    //   email: 'ebaldetti14@theguardian.com',
    //   currentPlan: 'team',
    //   status: 'pending',
    //   avatar: '',
    // },
    // {
    //   id: 42,
    //   name: 'Benedetto Rossiter',
    //   memID: 'Mybuzz PVT LTD',
    //   dayborn: 'editor',
    //   gender: 'brossiter15',
    //   dob: 'Indonesia',
    //   contact1: '(323) 175-6741',
    //   email: 'brossiter15@craigslist.org',
    //   currentPlan: 'team',
    //   status: 'inactive',
    //   avatar: '',
    // },
    // {
    //   id: 43,
    //   name: 'Micaela McNirlan',
    //   memID: 'Tambee PVT LTD',
    //   dayborn: 'admin',
    //   gender: 'mmcnirlan16',
    //   dob: 'Indonesia',
    //   contact1: '(242) 952-0916',
    //   email: 'mmcnirlan16@hc360.com',
    //   currentPlan: 'basic',
    //   status: 'inactive',
    //   avatar: '',
    // },
    // {
    //   id: 44,
    //   name: 'Vladamir Koschek',
    //   memID: 'Centimia PVT LTD',
    //   dayborn: 'author',
    //   gender: 'vkoschek17',
    //   dob: 'Guatemala',
    //   contact1: '(531) 758-8335',
    //   email: 'vkoschek17@abc.net.au',
    //   currentPlan: 'team',
    //   status: 'active',
    //   avatar: '',
    // },
    // {
    //   id: 45,
    //   name: 'Corrie Perot',
    //   memID: 'Flipopia PVT LTD',
    //   dayborn: 'subscriber',
    //   gender: 'cperot18',
    //   dob: 'China',
    //   contact1: '(659) 385-6808',
    //   email: 'cperot18@goo.ne.jp',
    //   currentPlan: 'team',
    //   status: 'pending',
    //   avatar: require('@/assets/images/avatars/3.png'),
    // },
    // {
    //   id: 46,
    //   name: 'Saunder Offner',
    //   memID: 'Skalith PVT LTD',
    //   dayborn: 'maintainer',
    //   gender: 'soffner19',
    //   dob: 'Poland',
    //   contact1: '(200) 586-2264',
    //   email: 'soffner19@mac.com',
    //   currentPlan: 'enterprise',
    //   status: 'pending',
    //   avatar: '',
    // },
    // {
    //   id: 47,
    //   name: 'Karena Courtliff',
    //   memID: 'Feedfire PVT LTD',
    //   dayborn: 'admin',
    //   gender: 'kcourtliff1a',
    //   dob: 'China',
    //   contact1: '(478) 199-0020',
    //   email: 'kcourtliff1a@bbc.co.uk',
    //   currentPlan: 'basic',
    //   status: 'active',
    //   avatar: require('@/assets/images/avatars/1.png'),
    // },
    // {
    //   id: 48,
    //   name: 'Onfre Wind',
    //   memID: 'Thoughtmix PVT LTD',
    //   dayborn: 'admin',
    //   gender: 'owind1b',
    //   dob: 'Ukraine',
    //   contact1: '(344) 262-7270',
    //   email: 'owind1b@yandex.ru',
    //   currentPlan: 'basic',
    //   status: 'pending',
    //   avatar: '',
    // },
    // {
    //   id: 49,
    //   name: 'Paulie Durber',
    //   memID: 'Babbleblab PVT LTD',
    //   dayborn: 'subscriber',
    //   gender: 'pdurber1c',
    //   dob: 'Sweden',
    //   contact1: '(694) 676-1275',
    //   email: 'pdurber1c@gov.uk',
    //   currentPlan: 'team',
    //   status: 'inactive',
    //   avatar: '',
    // },
    // {
    //   id: 50,
    //   name: 'Beverlie Krabbe',
    //   memID: 'Kaymbo PVT LTD',
    //   dayborn: 'editor',
    //   gender: 'bkrabbe1d',
    //   dob: 'China',
    //   contact1: '(397) 294-5153',
    //   email: 'bkrabbe1d@home.pl',
    //   currentPlan: 'memID',
    //   status: 'active',
    //   avatar: require('@/assets/images/avatars/9.png'),
    // },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/apps/members').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'fullName',
    sortDesc = false,
    status = null,
    gender = null,
    marital = null,
    dayborn = null,
  } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.members.filter(
    member =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (member.fullName.toLowerCase().includes(queryLowered) || member.dayborn.toLowerCase().includes(queryLowered) || member.contact1.toLowerCase().includes(queryLowered) || member.gender.toLowerCase().includes(queryLowered) || member.memID.toLowerCase().includes(queryLowered) ) &&
      member.status === (status || member.status) &&
      member.gender === (gender || member.gender) &&
      member.marital === (marital || member.marital) &&
      member.dayborn === (dayborn || member.dayborn),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      members: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new member
// ------------------------------------------------
mock.onPost('/apps/members').reply(config => {
  // Get event from post data
  const { member } = JSON.parse(config.data)

  console.log(member)

  // Assign Status
  // member.status = 'active'
  member.status = member.membershipType

  const { length } = data.members
  let lastIndex = 0
  if (length) {
    lastIndex = data.members[length - 1].id
  }
  member.id = lastIndex + 1
  member.memID = member.gender.substring(0,1).toUpperCase()+'00'+member.id
  member.fullName = member.name.other == '' ? (member.name.first + ' ' + member.name.last) : (member.name.first + ' ' + member.name.other + ' ' + member.name.last)
  member.contact1 =  {
    countryCode: '('+member.country+')',
    tel: member.contact1
  }

  data.members.push(member)

  return [201, { member }]
})

// ------------------------------------------------
// GET: Return Single User
// ------------------------------------------------
mock.onGet(/\/apps\/members\/\d+/).reply(config => {
  // Get event id from URL
  let memberId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  memberId = Number(memberId)

  const memberIndex = data.members.findIndex(e => e.id === memberId)
  const member = data.members[memberIndex]

  if (member) return [200, member]
  return [404]
})
