export default [
    {
      path: '/settings/user/list',
      name: 'settings-user-list',
      component: () => import('@/views/settings/users/user-list/UserList.vue'),
    },
    {
        path: '/settings/role/list',
        name: 'settings-role-list',
        component: () => import('@/views/settings/roles/role-list/RoleList.vue'),
      },
      {
        path: '/settings/account/profile',
        name: 'settings-account-profile',
        component: () => import('@/views/settings/account-profile/AccountSetting.vue'),
      },
    // {
    //   path: '/accounts/receipt/preview/:id',
    //   name: 'accounts-receipt-preview',
    //   component: () => import('@/views/accounts/receipt/receipt-preview/ReceiptPreview.vue'),
    // },

    
    // {
    //   path: '/accounts/payment/approvals',
    //   name: 'accounts-payment-approvals',
    //   component: () => import('@/views/accounts/payment/payment-approval/PaymentApproval.vue'),
    // },
    
    // membership
    // {
    //   path: '/account/members/list',
    //   name: 'account-members-list',
    //   component: () => import('@/views/account/membership/members-list/MemberList.vue'),
    // },
    // {
    //   path: '/account/members/view/:id',
    //   name: 'account-members-view',
    //   component: () => import('@/views/account/membership/members-view/MemberView.vue'),
    // },
    // {
    //   path: '/account/members/edit/:id',
    //   name: 'account-members-edit',
    //   component: () => import('@/views/account/membership/members-edit/MemberEdit.vue'),
    // },
    // {
    //   path: '/account/members/add',
    //   name: 'account-members-add',
    //   component: () => import('@/views/account/membership/members-add/MemberAdd.vue'),
    // },
  
    
  ]
  

