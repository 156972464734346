export default [
  {
    header: 'Dashboards',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Main',
        route: 'dashboard-ecommerce',
        icon: 'ShoppingCartIcon',
        action: 'read',
        resource: 'Dashboard-Ecommerce',
      },
      // {
      //   title: 'Analytics',
      //   route: 'dashboard-analytics',
      //   icon: 'ActivityIcon',
      //   action: 'read',
      //   resource: 'Dashboard-Analytics',
      // },
    ],
  },
]
