export default [
    {
        header: 'Settings',
        icon: 'SettingsIcon',
        children: [
            {
                title: 'Roles',
                icon: 'ListIcon',
                route: 'settings-role-list',
            },
            {
                title: 'Users',
                icon: 'UserIcon',
                children: [
                {
                    title: 'User List',
                    route: 'settings-user-list',
                },
                //   {
                //     title: 'Preview',
                //     route: { name: 'settings-receipt-preview', params: { id: 0 } },
                //   },
                //   {
                //     title: 'Approvals',
                //     route: 'settings-receipt-approvals',
                //   },
                ],
            },
            {
                title: 'Profile & Account',
                icon: 'LockIcon',
                route: 'settings-profile-list',
            }
        ]
    }
      
]