import Vue from 'vue'
import VueRouter from 'vue-router'
const userData = JSON.parse(localStorage.getItem('userData'))
import store from '../store'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import account from './routes/account'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import settings from './routes/settings'
// get layout
const type = store.state.appConfig.layout.type
// get navs for layout
import { default as index0 } from '../navigation/vertical/index'
import { default as index1 } from '../navigation/horizontal/index'
// set layout type
var index = type == 'horizontal' ? index1 : index0

Vue.use(VueRouter)

const _routes = [
  { path: '/', redirect: { name: 'dashboard-ecommerce' } },
  ...account,
  ...apps,
  ...dashboard,
  ...pages,
  ...chartsMaps,
  ...formsTable,
  ...uiElements,
  ...others,
  ...settings,
  {
    path: '*',
    redirect: 'error-404',
  },
]

// if (userData && userData.role !== "admin") {
  //
  for (var i=0; i < index.length; i++) {
    //
    let vl = index[i]
    //
    if (vl.route) {
      //
      let link
      
      if (typeof(vl.route) == 'string') { 
        
        link = _routes.find(ro => ro.name === vl.route) 
        
      } else if (typeof(vl.route) == 'object') {
        
        link = _routes.find(ro => ro.name === vl.route.name)

      }
      
      if (link) {
        link.meta = {
          resource: vl.resource,
          action: vl.action,
          contentRenderer: vl.contentRenderer,
          contentClass: vl.contentClass,
          navActiveLink: vl.navActiveLink,
        }
      }

    } else if (typeof(vl.children) !== "undefined") {

      for (var x=0; x < vl.children.length; x++) {
        
        let cvl = vl.children[x]
        // console.log(cvl.route)
        let xlink = null
        //
        if (typeof(cvl.route) == 'string') {
          
          xlink = _routes.find(ro => ro.name === cvl.route) 
        
        } else if (typeof(cvl.route) == 'object') {

          //
          let rname = cvl.route ? cvl.route.name : ''
          
          xlink = _routes.find(ro => ro.name === rname) 
        
        } else {
          xlink = null
        }

        if (xlink) {
          xlink.meta = {
            resource: cvl.resource,
            action: cvl.action,
            contentRenderer: cvl.contentRenderer,
            contentClass: cvl.contentClass,
            navActiveLink: cvl.navActiveLink,
          }
        }

      }
      // vl.children.forEach((cvl, ii) => {
        
      // })
      

    }
  }
// }
// 
// index.forEach((vl,i) => {
//   //
  
  
// })


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: _routes,
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
