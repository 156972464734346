export default [
    {
        header: 'Apps',
        icon: 'PackageIcon',
        children: [
            {
                title: 'Group',
                route: 'apps-group-list',
                icon: 'LayersIcon',
                action: 'read',
                resource: 'Group',
            },
            {
                title: 'Appointment',
                route: 'apps-appointment-list',
                icon: 'CheckSquareIcon',
                action: 'read',
                resource: 'Appointment',
            },
            {
                title: 'Attendance',
                icon: 'BookOpenIcon',
                children: [
                {
                    title: 'List',
                    route: 'apps-attendances-list',
                    action: 'read',
                    resource: 'Attendance-List',
                },
                ],
            },
            {
                title: 'Membership',
                icon: 'UsersIcon',
                children: [
                {
                    title: 'List',
                    route: 'apps-members-list',
                    action: 'read',
                    resource: 'Membership-List',
                },
                {
                    title: 'View',
                    route: { name: 'apps-members-view', params: { id: 0 } },
                    action: 'read',
                    resource: 'Membership-View',
                },
                {
                    title: 'Edit',
                    route: { name: 'apps-members-edit', params: { id: 0 } },
                    action: 'read',
                    resource: 'Membership',
                },
                {
                    title: 'Add',
                    route: 'apps-members-add',
                    action: 'read',
                    resource: 'Membership-Add',
                },
                ],
            },
            {
                title: 'Events',
                route: 'apps-calendar',
                icon: 'CalendarIcon',
                action: 'read',
                resource: 'Events',
            },
            {
                title: 'Task',
                route: 'apps-todo',
                icon: 'CheckCircleIcon',
                action: 'read',
                resource: 'Task',
                contentRenderer: 'sidebar-left',
                contentClass: 'todo-application',
            },
            {
                
                title: 'Sms',
                route: 'apps-sms-list',
                icon: 'MessageCircleIcon',
                action: 'read',
                resource: 'Sms',
            }
        ]
    }
]